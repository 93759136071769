<template>
  <div>
    <div v-if="certificate ">
      <div class="row">
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >Name</label>
          <input
            v-model="item.name"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">The human readable display name</span>
        </div>
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >SPID</label>
          <input
            v-model="item.spid"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">The SPID</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >STI-PA Account ID</label>
          <input
            v-model="item.account_id"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">The account ID used for accessing the STI-PA API</span>
        </div>
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >STI-PA User ID</label>
          <input
            v-model="item.user_id"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">The user ID used for accessing the STI-PA API</span>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >STI-PA Password</label>
          <input
            v-model="item.pass"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">The password used for accessing the STI-PA API</span>
        </div>
        <div class="mb-3 col">
          <label
            for="exampleFormControlInput1"
            class="form-label"
          >Comment</label>
          <input
            v-model="item.comment"
            type="text"
            class="form-control"
          >
          <span class="d-block mt-1">A short comment for additional information</span>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-success"
          @click="submit()"
        >
          Submit
        </button>
      </div>
    </div>
    <div v-else-if="!generateError && !generateSuccess && !validateToken && !tokenCreated">
      <a-radio-group
        :value="type"
        class="mb-5 d-flex justify-content-around"
        @change="(e) => (type = e.target.value)"
      >
        <a-radio :value="1">
          I have my own SPC token
        </a-radio>
        <a-radio :value="2">
          I want to let Peeringhub to generate and verify my SPC token
          <small class="d-block description-token">To use this option, you must add Peeringhub IP to your iConnectiv account's access whitelist.  Contact <br> support@peeringhub.io to obtain Peeringhub IP.</small>
        </a-radio>
      </a-radio-group>
      <div class="loader">
        <a-spin v-if="loading" />
      </div>
      <div v-if="type === 1">
        <a-form
          :model="tokenForm"
          class="d-flex flex-column mb-4"
        >
          <div class="d-flex">
            <h6
              class="mb-2"
              style="width: 120px;"
            >
              My SPC Token
            </h6>
            <a-textarea
              v-model:value="spc_token"
              placeholder="Enter your SPC Token"
              :auto-size="{ minRows: 2, maxRows: 6 }"
            />
          </div>
          <div class="d-flex pt-3">
            <h6
              class="mb-2"
              style="width: 120px;"
            >
              My OCN
            </h6>
            <a-input
              v-model:value="ocn"
              style="width: 100%"
              type="text"
            />
          </div>
          <div class="d-flex pt-3">
            <h6
              class="mb-2"
              style="width: 120px;"
            >
              Secret Phrase
            </h6>
            <a-input
              v-model:value="secret_phrase"
              type="text"
            />
          </div>
        </a-form>
        <a-button
          :disabled="!checkFormValidation"
          class="mt-2"
          type="primary"
          html-type="submit"
          @click="generateStiCertWithToken"
        >
          Generate Specify Token
        </a-button>
      </div>
      <div v-if="type === 2">
        <div v-if="!tokenCreated ">
          <div class="row mt-5 d-flex flex-column">
            <div class="mb-3 d-flex align-items-center">
              <h6 class="iConnective_Username">
                iConnective Username
              </h6>
              <input
                v-model="Iconnectiv_user"
                type="text"
                class="form-control"
              >
            </div>
            <div class="mb-3 d-flex align-items-center">
              <h6 class="iConnective_Username">
                iConnective Password
              </h6>
              <input
                v-model="Iconnectiv_pass"
                type="password"
                class="form-control"
              >
            </div>
            <div class="mb-3 d-flex align-items-center">
              <h6 class="iConnective_Username">
                My OCN
              </h6>
              <input
                v-model="ocn_token"
                type="text"
                class="form-control"
              >
            </div>
            <div class="mb-3 d-flex align-items-center">
              <h6 class="iConnective_Username">
                Secret Phrase
              </h6>
              <input
                v-model="secret_phrase_token"
                type="text"
                class="form-control"
              >
            </div>
          </div>




          <div class="d-flex justify-content-end">
            <button
              :disabled="!checkFormValidation"
              class="btn btn-success"
              @click="GenerateStiCertWithAuth"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="tokenCreated && !generateSuccess"
      class="mt-5"
    >
      <h6 class="mb-2">
        Your SPC Token is as follows:
      </h6>
      <a-form
        :model="createdTokenForm"
        class="d-flex flex-column align-items-end mb-4"
      >
        <a-textarea
          id="yourToken"
          v-model:value="createdTokenForm.token"
          placeholder="Your SPC Token"
          :auto-size="{ minRows: 2, maxRows: 6 }"
        />
        <div class="d-flex justify-content-center w-100 mt-1">
          <!--              save-->
          <a-button
            type="success"
            class="mb-2 ml-2"
            @click="saveToken"
          >
            <!--            <i-->
            <!--              class="fa fa-save icon"-->
            <!--              aria-hidden="true"-->
            <!--            />-->
            Save As
          </a-button>
          <!--                  copy-->
          <a-button
            type="success"
            class="mb-2 ml-2"
            @click="copyToken"
          >
            <!--            <i-->
            <!--              class="fa fa-copy icon"-->
            <!--              aria-hidden="true"-->
            <!--            />-->
            Copy
          </a-button>
          <!--                  email-->
          <a-button
            type="success"
            class="mb-2 ml-2"
            @click="emailToken"
          >
            <!--            <i-->
            <!--              class="fa fa-envelope icon"-->
            <!--              aria-hidden="true"-->
            <!--            />-->
            Email Me
          </a-button>
        </div>
        <a-button
          class="mt-2 mx-auto"
          type="primary"
          @click="generateSuccess = true"
        >
          Generate Certificate
        </a-button>
      </a-form>
    </div>
    <div
      v-if="generateError"
      class="text-center"
    >
      <h6>
        Sorry! Your request to create SPC Token has failed.  Here is the error message:
      </h6>
      <p class="text-danger">
        error massage
      </p>

      <span>
        Please contact STI-PA for assistance.
      </span>
    </div>

    <div v-if="generateSuccess">
      <div class="d-flex mb-5">
        <h6>Enter your secure key</h6>
        <div class="d-flex flex-column ml-3">
          <a-input
            v-model:value="secureKey"
            placeholder=""
          />
          <span>Your will need to use this secure key to extract your certificate. Please keep it in safe place.</span>
        </div>
      </div>

      <a-button
        :disabled="generateLoading"
        :class="generateLoading ? 'btn-secondary' : 'btn-success'"
        class="mt-2 btn"
        @click="generateLoading=true"
      >
        Generate Now
      </a-button>

      <div
        v-if="generateLoading"
        class="progress mt-5 text-center"
      >
        <div
          class="progress-bar bg-success"
          style="width: 50%"
        />
      </div>
    </div>

    <div v-if="validateToken && !certificate">
      <p>
        Reading SPC token Validating JWT format... PASSED <br>
        Checking SPC expiration time... PASSED <br>
        Validating fingerprint... PASSED <br>
        Extracting OCN... 818H <br>
        Checking JWT header... PASSED <br>
        Downloading PA certificate... DONE <br>
        Validating issuer... stdin: OK <br>
        Validating signature... PASSED <br>
      </p>

      <a-button
        class="mt-2 btn btn-success"
        @click="certificate = true"
      >
        Your SPC Token is validated. Click to generate Certificate.
      </a-button>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import {notification} from "ant-design-vue";
export default {
  data() {
    return {
      item: {
        name: null,
        spid: null,
        account_id: null,
        user_id: null,
        pass: null,
        comment: null,
      },
      secureKey: '',
      generateLoading: false,
      certificate: false,
      validateToken: false,
      generateSuccess: false,
      generateError: false,
      type: 1,
      tokenForm: {token: ''},
      createdTokenForm: {token: ''},
      createToken: false,
      tokenCreated: false,
      loading: false,
      spc_token: '',
      ocn: '',
      secret_phrase: '',
      checkFormValidation: false,


      ocn_token: '',
      secret_phrase_token: '',
      Iconnectiv_pass: '',
      Iconnectiv_user: '',
    }
  },
  watch: {
    spc_token(value){
      this.spc_token = value;
    },
    ocn(value){
      this.ocn = value;
    },
    secret_phrase(value) {
      this.secret_phrase = value;
      this.Validateform(value);
    },
    ocn_token(value) {
      this.ocn_token = value;
    },
    secret_phrase_token(value) {
      this.secret_phrase_token = value;
      this.Validateform(value);
    },
    Iconnectiv_pass(value) {
      this.Iconnectiv_pass = value
    },
    Iconnectiv_user(value) {
      this.Iconnectiv_user  = value
    }
  },

  methods: {
    submit() {
      this.$router.push('/listing')
    },

    checkTokenValidation(token) {
      const params = {
        spc: token,
        ocn: ''
      };
      axios
              .post('/certificate/verify_spc', params)
              .then(async (res) => {
                console.log("resss", res)
                this.validateToken = true
              })
              .catch((err) => {
                this.$store.dispatch('error', err)
              })
    },

    createTokenFunction() {
      const params = {
        "company_name": this.item.name,
        "SPID": this.item.spid,
        "STI-PA-Account ID": this.item.account_id,
        "STI-PA-User ID": this.item.user_id,
        "Comment": this.item.comment,
        "password": this.item.pass,
      };
      axios
              .post('/certificate/generate_certificate', params)
              .then(async (res) => {
                console.log("resss", res)
                this.tokenCreated = true
              })
              .catch((err) => {
                this.$store.dispatch('error', err)
              })
    },

    generateCertificate() {
      this.generateSuccess = true;
    },

    saveToken() {
      const content = ''
      var uriContent = "data:application/octet-stream," + encodeURIComponent(content);
      window.open(uriContent, 'neuesDocument');
    },

    copyToken() {
        var copyText = document.getElementById("yourToken");
        copyText.select();
        document.execCommand("Copy");
        alert("Copied the text: " + copyText.value);
    },

    emailToken() {
      const userEmail = "email@example.com"
      const token = document.getElementById("yourToken").value;
      window.open(`mailto:${userEmail}?subject=YourToken&body=${token}`);
    },

    generateStiCertWithToken() {
        const params = {
          spc_token: this.spc_token,
          ocn: this.ocn,
          secret_phrase: this.secret_phrase
        };
        this.checkFormValidation = false
      axios
          .post('/certificate/generate_sti_cert_with_token', params)
          .then(async (res) => {
            if(res.data.success === true) {
              notification.success({
                message: 'Successfuly Generate',
              })
            }else {
              notification.error({
                message: 'We are not able to generate your SPC token.  Please confirm with iConnectiv that you have whitelist Peeringhub’s IP address in your account whilelist.\n' +
                    '\n' +
                    'If you don’t have Peeringhub’s IP, please contact us at support@peeringhub.io.',
              })
            }
          })
          .catch((err) => {console.log(err)})
      .finally(() => {
        this.spc_token = '';
        this.ocn = '';
        this.secret_phrase = ''
      })
      },
    GenerateStiCertWithAuth() {
      const params = {
        Iconnectiv_user: this.Iconnectiv_user,
        Iconnectiv_pass: this.Iconnectiv_pass,
        ocn: this.ocn_token,
        secret_phrase: this.secret_phrase_token
      };
      this.checkFormValidation = false
      this.loading = true
      axios
          .post('/certificate/generate_sti_cert_with_auth', params)
          .then(async (res) => {
            this.loading = false
            if(res.data.success === true) {
              notification.success({
                message: 'Successfuly Generated',
              })
            }else {
              this.loading = false
              notification.error({
                message: 'We are not able to generate your SPC token.  Please confirm with iConnectiv that you have whitelist Peeringhub’s IP address in your account whilelist.\n' +
                    '\n' +
                    'If you don’t have Peeringhub’s IP, please contact us at support@peeringhub.io.',
              })
            }
          })
          .catch((err) => {
            this.$store.dispatch('error', err)
          })
      .finally(() => {
        this.Iconnectiv_user = '';
        this.Iconnectiv_pass = '';
        this.ocn_token = '';
        this.secret_phrase_token = ''
      })
      },
    Validateform (value) {
      if(value !== '') {
        this.checkFormValidation = true
      }
    }
  }
}
</script>
<style scoped>
span {
  font-size: 10px;
}

  .icon {
    display: flex;
    justify-content: center;
    font-size: 17px;
  }

.form-label {
  font-size: 14px !important;
  color: #595c97 !important;
  font-weight: 400 !important;
}
.description-token {
  margin-left: 26px;
}
.iConnective_Username {
  width: 100%;
  max-width: 165px;
}
.loader {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>